import React from "react";
import newLogo from "../../assets/newlogo.png";
import christReignsLogo from "../../assets/christreigns-logo.png";

const AboutMeIntro: React.FC = () => {
  return (
    <div className="relative bg-black bg-opacity-50 rounded-lg p-6 shadow-lg max-w-sm border border-white">
      {/* Profile Section */}
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center mx-auto xl:mx-0 space-x-3">
            <img
              src={newLogo}
              alt="Live For Jesus Christ"
              className="w-12 h-12 md:w-20 md:h-20 rounded-full border-2 border-white"
            />
            <img
              src={christReignsLogo}
              alt="Living With Jesus Logo"
              className="h-10"
            />
          </div>    
      </div>

      {/* Short Bio */}
      <p className="text-gray-300 text-sm mb-4">
        Christ Reigns Media is a nonprofit project dedicated to encouraging a
        life centered on Jesus Christ. We create and share content—including
        digital music, software, online articles, and valuable resources—to
        inspire and promote a Christ-centered way of living. Our mission is to
        provide uplifting and insightful content that deepens your understanding
        of the beauty and fulfillment found in following our Lord and Savior,
        Jesus Christ.
      </p>


    
      <div className="flex flex-col md:flex-row gap-6">
        {/* People I Follow Section */}
        <div className="flex-1">
          <h4 className="text-lg font-bold text-yellow-400 mb-2">
            Recommended Channels:
          </h4>

          <ul className="space-y-2">
            <li>
              <a
                href="https://www.youtube.com/@graciacc"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Gracia Calvary Chapel
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@raycomfortjustwitnessing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Ray Comfort
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/theBibleProject"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                The Bible Project
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@JonJorgensonVideos"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                John Jorgenson
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/watch?v=9jYtODX22ZY&t=5s"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Voddie Baucham
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@calvaryftl"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Calvary Chapel Fort Lauderdale
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/LivingWaters"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Living Waters
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@nicholasbowlingministries"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Nicholas Bowling Ministries
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@thebeatagp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                THE BEAT by Allen Parr
              </a>
            </li>
            <li></li>
            <li>
              <a
                href="https://www.youtube.com/@craiggroeschel"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Craig Groeschel
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@goodfightministries"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Good Fight Ministries
              </a>
            </li>
          </ul>
        </div>

        {/* References Section */}
        <div className="flex-1">
          <h4 className="text-lg font-bold text-yellow-400 mb-2">
            References:
          </h4>
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.youversion.com/the-bible-app/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                YouVersion Bible App
              </a>
            </li>
            <li>
              <a
                href="https://biblehub.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Bible Hub
              </a>
            </li>
            <li>
              <a
                href="https://biblestudytools.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Bible Study Tools
              </a>
            </li>
            <li>
              <a
                href="https://livingwaters.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Living Waters Site
              </a>
            </li>
            <li>
              <a
                href="https://watch.thechosen.tv/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                The Chosen
              </a>
            </li>
            <li>
              <a
                href="https://www.tyndale.com/sites/lasb/?srsltid=AfmBOopCe9OY-LiBxGYmSnnfXTSe8_EO4ICfWPCLzv6NH2n7RwZTIww0"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Life Application Study Bible
              </a>
            </li>
            <li>
              <a
                href="https://paper.bible/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Paper Bible - Free app for printing Scripture
              </a>
            </li>
            <li>
              <a
                href="https://www.goodfight.org/sold-souls-free/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 underline text-sm"
              >
                Good Fight Ministries - They Sold Their Souls for Rock and Roll
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutMeIntro;
