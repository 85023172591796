import React from "react";
import { FaEnvelope, FaFacebook, FaYoutube, FaMedium } from "react-icons/fa";
import dashboardApp from "../../assets/dashboardapp.png";

export default function FuturePlans() {
  return (
    <div className="relative bg-black bg-opacity-50 p-6 shadow-lg rounded-lg max-w-md border border-white">
      {/* Future Plans Section */}
      <div className="relative z-10">
        <div className="text-sm font-bold mb-2 uppercase">
          Now Available Beta Version:{" "}
          <p className="text-blue-400 text-sm uppercase">
            Christ Reigns Dashboard
          </p>
        </div>
        <p className="text-left text-sm text-white mb-4">
          Take a look at our brand new accompanying Dashboard application
          (curently on Beta) that will help you track your spiritual growth and
          development. This application is designed to help you keep track of
          your Bible study progress, apologetics training resources, spiritual
          goals, and personal space for all things related to your walk with
          Jesus Christ. Let us know if you would like to try it out for free access,
          there is absolutely no charge as we are a non profit project, organization.
        </p>
        <a
          href="https://liveforjesuschrist.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="overflow-hidden">
            <img
              src={dashboardApp}
              alt="Living For Jesus Christ Dashboard App"
              className="w-full h-auto rounded-lg shadow-lg mb-4 transform transition-transform duration-300 hover:scale-110"
            />
          </div>
        </a>

        <p className="text-left text-sm text-blue-400 mb-4">
          <a
            href="https://liveforjesuschrist.tech"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            https://liveforjesuschrist.tech/
          </a>
        </p>
      </div>

      {/* Social Icons */}
      <div className="relative z-10 mt-6">
        <ul className="text-left text-white text-sm space-y-3">
          <li className="flex items-center space-x-4">
            <a
              href="https://www.youtube.com/@christ-reigns-media"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              <FaYoutube className="text-white text-4xl" />
            </a>

            <a
              href="https://www.facebook.com/christReignsMedia/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              <FaFacebook className="text-white text-4xl" />
            </a>

            <a
              href="https://medium.com/@christreigns"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              <FaMedium className="text-white text-4xl" />
            </a>

            <a
              href="mailto:alan.jones170183@gmail.com"
              className="hover:text-blue-400 transition"
            >
              <FaEnvelope className="text-white text-4xl" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
