import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import newLogo from "../assets/newlogo.png";
import MobileNav from "./MobileNav";
import christReignsLogo from "../assets/christreigns-logo.png";

interface HeaderProps {
  scrollToSection?: (section: string) => void;
}

const Header: React.FC<HeaderProps> = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (section: string) => {
    if (scrollToSection) {
      scrollToSection(section);
    } else {
      navigate("/", { state: { section } });
    }
  };

  return (
    <>
      <header className="bg-black bg-opacity-70 shadow-md fixed w-full z-50 border-b-2 border-white">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center mx-auto xl:mx-0 space-x-3">
            <img
              src={newLogo}
              alt="Live For Jesus Christ"
              className="w-12 h-12 md:w-20 md:h-20 rounded-full border-2 border-white"
            />
            <img
              src={christReignsLogo}
              alt="Living With Jesus Logo"
              className="h-10"
            />
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden xl:flex space-x-6">
            {/* <button
              onClick={() => handleNavigation("homeIntro")}
              className="text-white hover:text-blue-400"
            >
              Home
            </button> */}
            <button
              onClick={() => handleNavigation("blogList")}
              className="text-white hover:text-blue-400 uppercase font-bold"
            >
              Articles
            </button>
            <button
              onClick={() => handleNavigation("bibleSearch")}
              className="text-white hover:text-blue-400 uppercase font-bold"
            >
              Quick Bible Verse Tool
            </button>
            <button
              onClick={() => handleNavigation("flipBooks")}
              className="text-white hover:text-blue-400 uppercase font-bold"
            >
              Bible Resources
            </button>
            <button
              onClick={() => handleNavigation("bibleApp")}
              className="text-white hover:text-blue-400 uppercase font-bold"
            >
              Bible App
            </button>
            <button
              onClick={() => handleNavigation("media")}
              className="text-white hover:text-blue-400 uppercase font-bold"
            >
              Media
            </button>
            <button
              onClick={() => handleNavigation("footer")}
              className="text-white hover:text-blue-400 uppercase font-bold"
            >
              Contact
            </button>
            <button
              onClick={() =>
                window.open("https://liveforjesuschrist.tech", "_blank")
              }
              className="text-white font-bold bg-blue-500 px-4 py-2 rounded hover:bg-blue-700 uppercase"
            >
              Login
            </button>
          </nav>
        </div>
      </header>

      {/* Mobile Navigation */}
      <MobileNav onNavigate={handleNavigation} />
    </>
  );
};

export default Header;
