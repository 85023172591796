import React from "react";
import FuturePlans from "./FuturePlans";
import bibleBackground from "../../assets/bible.png";
import AboutMeIntro from "./AboutMe";


const Footer: React.FC = () => {
  return (
    <footer className="relative text-white pt-10 pb-6 border-t-2 border-white drop-shadow-lg">
      {/* Background Image Layer */}
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${bibleBackground})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      ></div>

      {/* Black Overlay for Opacity */}
      <div className="absolute inset-0 bg-black bg-opacity-60 z-0"></div>

      {/* Footer Content */}
      <div className="container mx-auto px-6 md:px-12 relative z-10 grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Section - About Me Intro */}
        <div>
          <AboutMeIntro />
        </div>

        {/* Right Section - Future Plans */}
        <div>
          <FuturePlans />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
