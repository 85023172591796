import React from "react";
import {
  FaHome,
  FaBook,
  FaEnvelope,
  FaPlay,
  FaNewspaper,
  FaBible,
  FaToolbox,
  FaUser,
  FaTools,
} from "react-icons/fa";

interface MobileNavProps {
  onNavigate: (section: string) => void;
}

const MobileNav: React.FC<MobileNavProps> = ({ onNavigate }) => {
  return (
    <nav className="fixed bottom-0 left-0 w-full bg-black bg-opacity-70 z-50 xl:hidden border-t-2 border-white">
      <div className="grid grid-cols-4 gap-5 justify-items-center items-center p-5">
        <button
          onClick={() => onNavigate("homeIntro")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaHome size={20} />
          <span className="text-xs">Home</span>
        </button>
        <button
          onClick={() => onNavigate("blogList")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaNewspaper size={20} />
          <span className="text-xs">Articles</span>
        </button>
        <button
          onClick={() => onNavigate("bibleSearch")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaTools size={20} />
          <span className="text-xs">Bible Tool</span>
        </button>
        <button
          onClick={() => onNavigate("flipBooks")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaToolbox size={20} />
          <span className="text-xs">Resources</span>
        </button>
        <button
          onClick={() => onNavigate("bibleApp")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaBible size={20} />
          <span className="text-xs">Bible App</span>
        </button>
        <button
          onClick={() => onNavigate("media")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaPlay size={20} />
          <span className="text-xs">Media</span>
        </button>
        <button
          onClick={() => onNavigate("footer")}
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaEnvelope size={20} />
          <span className="text-xs">Contact</span>
        </button>
        <button
          onClick={() =>
            window.open("https://liveforjesuschrist.tech", "_blank")
          }
          className="flex flex-col items-center text-white hover:text-blue-400"
        >
          <FaUser size={20} />
          <span className="text-xs">Login</span>
        </button>
      </div>
    </nav>
  );
};

export default MobileNav;
